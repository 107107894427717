import {mapMutations} from "vuex";

export default {
    name:"FocusedInformationCard",

    props: {
        id: {
            required: true
        },
        isDownloadable: {
            type: Boolean,
            default: true
        },
        isDownloadableAsZip: {
            type: Boolean,
            default: false
        },
        isBookmarked: {
            type: Boolean,
            default: false
        },
        isBookmarkLoading: {
            type: Boolean,
            default: false
        },
        showShareButton: {
            type: Boolean,
            default: true
        }
    },

    data() {
        return {
            location: window.location.href
        }
    },

    methods: {
        ...mapMutations('notification', ['notify']),

        close() {
            this.$emit("close", this.id)
        },

        bookmark() {
            this.$emit("bookmark", this.id)
        },

        download() {
            this.$emit("download", this.id)
        },

        downloadAsZip() {
            this.$emit("downloadzip", this.id)
        },

        clipboardSuccessHandler () {
            this.notify({message: 'تم نسخ الرابط لمشاركته', show: true})
        },
       
        clipboardErrorHandler () {
            this.notify({message: 'هناك خطأ ما. الرجاء معاودة المحاولة في وقت لاحق.', show: true})
        }
    }
}