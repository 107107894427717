const THEMES = ['gray', 'orange', 'blue'];
const DIRECTIONS = ['rtl', 'ltr'];

export default {
    name:"InformationCard", 

    props: {
        data: {
            type: Object,
            required: true,
            default() { return {} }
        },
        theme: {
            type: String,
            default: THEMES[0],
            validator: (val) => THEMES.includes(val)
        },
        showDescription: {
            type: Boolean,
            default: false
        },
        showMoreDescription: {
            type: Boolean,
            default: false
        },
        imgHeight: {
            type: String,
            default: "180px"
        },
        direction: {
            type: String,
            default: DIRECTIONS[0],
            validator: (val) => DIRECTIONS.includes(val)
        },
        showFullDescription: {
            type: Boolean,
            default: false
        }
    },
    
    data() {
        return {
            information: {},
            chosenTheme: this.theme
        }
    },

    methods: {
        setTheme(theme) {
            const isThemeValid = THEMES.includes(theme);
            isThemeValid? this.chosenTheme = theme : this.chosenTheme = THEMES[0]
        },
        handleClick(id) {
            this.$emit("click", id)
        },
        showMore(id) {
            this.$emit("show-more", id)
        }
    },

    watch: {
        data: {
            immediate: true,
            deep: true,
            handler(value) {
                this.information = value
            }
        },
        theme: {
            immediate: true,
            handler(value) {
                this.setTheme(value)
            }
        }
    }
}